import {BrowserRouter, Link, Route, Redirect} from "react-router-dom";
import SingleExpression from "./components/SingleExpression";
import RatingPage from "./components/Rating";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <section>
          <div className="terminal-nav">
            <div className="terminal-logo">
              <div className="logo terminal-prompt"><a href="#" className="no-style">Datagate Feed Testing</a></div>
            </div>
            <nav className="terminal-menu">
              <ul>
                <li><Link to="/expressions">Expressions</Link></li>
                <li><Link to="/rating">Rating</Link></li>
              </ul>
            </nav>
          </div>
        </section>

        <Route exact path="/" render={() => (<Redirect to="/expressions" />)} />
        <Route path="/expressions" exact component={SingleExpression} />
        <Route path="/rating" exact component={RatingPage} />
      </BrowserRouter>
    </div>
  );
}

export default App;
