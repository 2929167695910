import {useState} from "react";
import axios from "axios";

export default function RatingPage(props) {

  const [shardId, setShardId] = useState("");
  const [usageId, setUsageId] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const send = () => {
    const data = {
      shardId: parseInt(shardId),
      usageId: parseInt(usageId),
    };

    setError(null);

    axios.post("/rating-engine/api/process-single-usage", data)
      .then(function (response) {
        // handle success
        setResult(response.data);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      })
      .then(function (response) {
        // always executed
      });
  }

  return (
    <div>

      <fieldset>
        <legend>Parameters</legend>
        <div className="form-group">
          <input type="text" placeholder="Shard ID" type="number" onChange={(e) => {
            setShardId(e.target.value)
          }} value={shardId}/>
        </div>
        <div className="form-group">
          <input type="text" placeholder="Usage ID" type="number" onChange={(e) => {
            setUsageId(e.target.value)
          }} value={usageId}/>
        </div>
        <div className="form-group">
          <button className="btn btn-default" disabled={shardId === "" || usageId === ""} onClick={() => send()}>Rate
          </button>
        </div>
      </fieldset>

      {error && (
        <div>
          <hr/>
          <section>
            <div className="terminal-alert terminal-alert-error">{error.toString()}</div>
          </section>
        </div>
      )}

      {result && (
        <>
          <hr/>

          <section>
            <header>
              <h2>Result</h2>
            </header>
            <table>
              <thead>
              <tr>
                <th></th>
                <th>Field</th>
                <th>Value</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td></td>
                <td>ProcessStageId</td>
                <td>{result.processStageId}</td>
              </tr>
              <tr>
                <td></td>
                <td>ProcessErrorId</td>
                <td>{result.processErrorId}</td>
              </tr>
              <tr>
                <td></td>
                <td>StatusId</td>
                <td>{result.statusId}</td>
              </tr>
              <tr>
                <td></td>
                <td>Sell</td>
                <td>{result.sell}</td>
              </tr>
              <tr>
                <td></td>
                <td>InvoiceSell</td>
                <td>{result.invoiceSell}</td>
              </tr>
              <tr>
                <td></td>
                <td>RecordType</td>
                <td>{result.recordType}</td>
              </tr>
              <tr>
                <td></td>
                <td>RateCardId</td>
                <td>{result.rateCardId}</td>
              </tr>
              <tr>
                <td></td>
                <td>RateCardAssignmentId</td>
                <td>{result.rateCardAssignmentId}</td>
              </tr>
              <tr>
                <td></td>
                <td>RatingMethodCode</td>
                <td>{result.ratingMethodCode}</td>
              </tr>
              <tr>
                <td></td>
                <td>TaxOutputRateId</td>
                <td>{result.taxOutputRateId}</td>
              </tr>
              <tr>
                <td></td>
                <td>TaxOutputRatePercentage</td>
                <td>{result.taxOutputRatePercentage}</td>
              </tr>
              </tbody>
            </table>
          </section>

          <fieldset>
            <legend>Logs</legend>
            {Object.keys(result.log).map(key => (
              <>
                <span key={key}>{result.log[key]}</span><br/>
              </>
            ))}
          </fieldset>

        </>
      )}

    </div>
  );
}