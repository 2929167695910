import {useState} from "react";
import axios from "axios";
import ColVariable from "./ColVariable";

export default function SingleExpression(props) {
  const [expression, setExpression] = useState("");
  const [cols, setCols] = useState(["", "", "", "", "", "", "", "", "", ""]);
  const [result, setResult] = useState("");

  const [error, setError] = useState(null);

  function setColValue(index, val) {
    let newCols = [...cols];
    newCols[index] = val;
    setCols(newCols);
  }

  function send() {

    setError(null);

    let data = {
      col1: cols[0],
      col2: cols[1],
      col3: cols[2],
      col4: cols[3],
      col5: cols[4],
      col6: cols[5],
      col7: cols[6],
      col8: cols[7],
      col9: cols[8],
      col10: cols[9],
      expression: expression
    }

    axios.post("/feed-processor/api/process-single-rule", data)
      .then(function (response) {
        // handle success
        setResult(response.data.result);
      })
      .catch(function (error) {
        // handle error
        setError(error);
      })
      .then(function (response) {
        // always executed
      });
  }

  return (
    <div>

      <div style={{display: 'flex', flexDirection: 'row'}}>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <fieldset>
            <legend>Variables</legend>
            <ColVariable index={1} val={cols[0]} onChange={val => setColValue(0, val)} />
            <ColVariable index={2} val={cols[1]} onChange={val => setColValue(1, val)} />
            <ColVariable index={3} val={cols[2]} onChange={val => setColValue(2, val)} />
            <ColVariable index={4} val={cols[3]} onChange={val => setColValue(3, val)} />
            <ColVariable index={5} val={cols[4]} onChange={val => setColValue(4, val)} />
            <ColVariable index={6} val={cols[5]} onChange={val => setColValue(5, val)} />
            <ColVariable index={7} val={cols[6]} onChange={val => setColValue(6, val)} />
            <ColVariable index={8} val={cols[7]} onChange={val => setColValue(7, val)} />
            <ColVariable index={9} val={cols[8]} onChange={val => setColValue(8, val)} />
          </fieldset>
        </div>


        <div>
          <fieldset>
            <legend>Expression</legend>
            <textarea rows="30" cols="50" value={expression} onChange={(e) => setExpression(e.target.value)}/>
          </fieldset>
        </div>

      </div>

      <div className="form-group">
        <button className="btn btn-default" onClick={send}>Submit</button>
      </div>

      {error && (
        <div>
          <hr/>
          <section>
            <div className="terminal-alert terminal-alert-error">{error.toString()}</div>
          </section>
        </div>
      )}

      <hr/>

      <section>
        <fieldset>
          <legend>Result</legend>
          {result}
        </fieldset>
      </section>
    </div>
  );
}